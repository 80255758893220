/* Definir una variable CSS para el color del botón seleccionado */
:root {
  --button-color: #fff;
  --logout-color: red;
  --letra-color: #430f0c;
}

/* Mobile-first styles */
.navbar {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 80px;
}

/* Alinear los botones al borde superior */
.navbar-buttons {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navbar-logo {
  width: 100px;
  height: auto;
}

.navbar-right .ant-dropdown-trigger {
  width: 150px;
  text-align: center;
}

/* Estilos específicos para los botones de la navbar */
button.navbar-button {
  border: none;
  color: var(--letra-color) !important;
  background-color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.25em;
  margin-right: 10px;
  padding: 0 10px;
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hover para los botones de la navbar */
button.navbar-button:hover {
  color: var(--letra-color) !important;
  font-weight: bold;
  border: none; /* Sin borde en hover */
}

/* Botón activo */
button.navbar-button.active-button {
  font-weight: bold;
  color: var(--letra-color) !important;
  border: 2px solid var(--letra-color); /* Borde rojo oscuro */
}

/* Botón de "Cerrar Sesión" mantiene su estilo */
button.danger {
  border: 2px solid var(--letra-color); /* Borde rojo oscuro */
  font-size: 1.25em;
  margin-right: 10px;
  padding: 0 10px;
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button.danger:hover {
  color: red !important;
  border: 2px solid red !important; /* Borde rojo oscuro */
}

/* Información de usuario debajo del navbar */
.user-info {
  margin-top: 10px;
  padding-left: 20px;
}

.navbar-user-info {
  color: var(--letra-color) !important;
  font-size: 1.25em;
  display: block;
}

/* Estilos para los elementos del dropdown en pantallas grandes */
.ant-dropdown-menu {
  background-color: white;
  font-size: 1.25em; /* Aumenta el tamaño de letra */
  padding: 10px; /* Añade padding para espaciado */
  min-width: 180px; /* Aumenta el ancho mínimo del dropdown */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Estilos para los items del dropdown */
.ant-dropdown-menu-item {
  padding: 12px 20px;
  font-size: 1.25em; /* Mismo tamaño de letra que los botones */
  color: var(--letra-color); /* Mismo color que los botones */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover en los items del dropdown */
.ant-dropdown-menu-item:hover {
  background-color: #f0f0f0; /* Fondo gris claro en hover */
  color: var(--letra-color); /* Mantiene el mismo color en hover */
  font-weight: bold; /* Negrita al hacer hover */
}

/* Dropdown general con más ancho y letra más grande en móviles */
@media (max-width: 1200px) {
  .ant-dropdown-menu {
    font-size: 1.5em; /* Aumenta el tamaño de letra en móviles */
    min-width: 200px; /* Aumenta el ancho mínimo en móviles */
  }

  .ant-dropdown-trigger {
    font-size: 1.5em !important; /* Aumenta el tamaño del botón del dropdown en móviles */
  }

  .ant-dropdown-menu-item {
    font-size: 1.5em !important; /* Aumenta el tamaño de los elementos del menú en móviles */
  }
}

/* Responsive (pantallas más grandes) */
@media (min-width: 768px) {
  .navbar-buttons button {
    margin-left: 10px;
  }

  .navbar-logo {
    width: 200px;
  }

  /* Estilo para dropdowns en pantallas grandes */
  .ant-dropdown-trigger,
  .ant-dropdown-menu-item {
    font-size: 1.25em !important; /* Aplicar tamaño grande en los dropdowns también */
  }

  .ant-dropdown-menu {
    min-width: 250px; /* Aumenta el ancho mínimo del dropdown en pantallas grandes */
  }

  .ant-dropdown-menu-item {
    padding: 15px 20px; /* Aumenta el padding para un diseño más espacioso */
  }
}
