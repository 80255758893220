.ant-layout-content {
  padding: 5px !important; /* Sobrescribe el padding inline con 5px */
}
.admin-container {
  display: flex;
  flex-direction: column; /* Mobile-first: apilados en una columna */
  width: 100%;
}

.details-column {
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  order: 1; /* Móvil: mostrar detalles primero */
}

.user-management-column {
  background-color: #f5f5f5;
  padding: 20px;
  order: 2; /* Móvil: mostrar gestor de usuarios después */
}

/* Ajustes para pantallas grandes */
@media (min-width: 768px) {
  .admin-container {
    flex-direction: row; /* Para pantallas grandes: apilados en fila */
  }

  .details-column {
    order: 2; /* Escritorio: mostrar detalles a la derecha */
  }

  .user-management-column {
    order: 1; /* Escritorio: mostrar gestor a la izquierda */
  }
}
