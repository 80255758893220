/* Estilos globales para el componente Login */
:root {
  --primary-color: #c96d17;
  --secondary-color: #b3cb2a;
  --input-border-color: #ced4da;
}

.container-login {
  max-width: 400px;
  margin: 100px auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Estilo para el título */
h2 {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 30px;
}

/* Estilo de los botones */
button {
  border-radius: 5px;
  padding: 10px;
}

button:hover {
  opacity: 0.9;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn-primary {
  background-color: var(--primary-color);
  border: none;
}

.btn-secondary {
  background-color: var(--secondary-color);
  border: none;
}

/* Estilo para las etiquetas del formulario */
.form-label {
  color: var(--primary-color);
}

/* Input group para mostrar la contraseña */
.input-group-text {
  cursor: pointer;
}

/* Input personalizado */
.form-control {
  border-color: var(--input-border-color);
}

/* Diseño responsive */
@media (max-width: 768px) {
  .container-login {
    max-width: 90%;
    margin-top: 50px;
  }
}
